// i18next-extract-mark-ns-start markets-aerospace

import { CAPABILITIES_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketAerospacePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Simulating low emission aerospace technology</h2>

						<p>
							The aerospace industry has targets to achieve net zero aviation by 2050. Significant steps are being made in reducing emissions and
							improving fuel economy which are now being supported with new technology such as electric, hydrogen and the use of alternative e-fuels.
						</p>
						<p>
							Aerospace propulsion manufacturers need to balance the requirements to continue the optimisation of existing engines with lower emissions
							and increased fuel economy as well as introduce new non-carbon technology.
						</p>
						<p>
							The design challenge is to consider the available options across full electric, hydrogen, e-fuels and existing technologies in a quick,
							efficient and cost-effective manner.
							In the initial stages of development, physical prototype modelling is expensive, requiring virtual simulation tools to answer
							many of the 'What ifs' before committing to a design path.
						</p>
						<p>
							Simulation of the propulsion system is at the heart of aerospace design, especially in predicting performance in key areas such as aerodynamics,
							thermal management and engine behaviour across a range of fuels and duty cycles.
						</p>
						<p>
							At Realis Simulation we have extensive experience of using software to streamline the propulsion system design process –
							answering many of the ‘What ifs’ using computer simulation before Original Equipment Manufacturers (OEMs) and Tier 1 suppliers
							commit to expensive physical prototypes.
						</p>
						<p>
							Our software models the full propulsion system design from fuel intake, power generation, power transfer and exhaust emissions reduction.
							It simulates energy losses from friction, oil consumption and thermal heat transfer as well as component durability across various drive cycles
							using traditional, non-carbon e-fuels, hybrid, electric, hydrogen fuel cells and hydrogen combustion power units.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default MarketAerospacePage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-aerospace", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/aerospace/Realis-Simulation_1920_Aerospace.jpg" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { markets: { in: "Aerospace" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 9
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
